import { Timestamp, arrayUnion } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { putDoc } from "../../firebase";
import "./AddService.css";
import { useNavigate } from "react-router-dom";

const AddService = ({ hideAdd, videos, userDoc, setVideos }) => {
  const navigate = useNavigate();
  const [link, setLink] = useState("");
  const [src, setSrc] = useState("");
  const [title, setTitle] = useState("Source");
  const [service, setService] = useState("");

  useEffect(() => {
    if (userDoc?.role !== "admin") {
      alert("You do not have the permission to view this page");
      navigate("/");
    }
  }, [userDoc?.role, navigate]);

  const path = window.location.pathname;

  useEffect(() => {
    if (path.includes("recording")) {
      setService("recording");
    } else if (path.includes("arranging")) {
      setService("arranging");
    } else if (path.includes("sound-design")) {
      setService("sound");
    } else if (path.includes("mix-master")) {
      setService("mix");
    } else {
      setService("");
    }
  }, [setService, path]);

  // const handleLink = () => {
  //   if (src === "yt") {
  //     return `www.youtube.com/embed/${link.substring(
  //       link.lastIndexOf("=") + 1
  //     )}`;
  //   } else {
  //     return link;
  //   }
  // };

  const handleLink = () => {
    if (src === "yt") {
      return `https://www.youtube.com/embed/${link.substring(
        link.lastIndexOf("=") + 1
      )}`;
    } else if (src === "fb") {
      let channelBefore = link.substring(link.indexOf(".com/") + 5);
      let channel = channelBefore.substring(
        0,
        channelBefore.indexOf("/videos")
      );
      let videoIdBefore = link.substring(link.lastIndexOf("videos/") + 7);
      let videoId = videoIdBefore.substring(0, videoIdBefore.indexOf("/"));
      return `https://www.facebook.com/plugins/video.php?height=230&href=https%3A%2F%2Fweb.facebook.com%2F${channel}%2Fvideos%2F${videoId}%2F&show_text=false&width=300&t=0`;
    }
  };

  const newObject = {
    link: handleLink(),
    timestamp: Timestamp.fromDate(new Date()).seconds,
    src: src,
  };

  const filtered = videos?.map((video) => {
    return video?.link;
  });

  const handleSubmit = () => {
    if (filtered?.includes(link)) {
      alert("Duplicate video link found");
    } else {
      putDoc("videos", service, { list: arrayUnion(newObject) })
        .then(() => {
          setSrc("");
          setTitle("Source");
          setLink("");
        })
        .then(alert("Video has been added"))
        .then(setVideos([...videos, newObject]));
    }
  };

  return (
    <div id="add-recording-div">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fback-button.png?alt=media&token=61eeafb2-af04-41d2-8e73-435981a6bbac&_gl=1*mxo7pn*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTY5MDEwNi41NC4xLjE2ODU2OTI1NjYuMC4wLjA."
        id="add-recording-back-btn"
        alt="back"
        onClick={hideAdd}
      />
      <Row>
        <h3 id="add-recording-title">Add video to {service} page</h3>
      </Row>
      <DropdownButton
        title={title}
        id="add-recording-src-btn"
        drop="down-centered"
      >
        <Dropdown.Item
          onClick={() => {
            setSrc("yt");
            setTitle("Youtube");
          }}
        >
          Youtube
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setSrc("fb");
            setTitle("Facebook");
          }}
        >
          Facebook
        </Dropdown.Item>
      </DropdownButton>
      {!src ? (
        ""
      ) : (
        <Row>
          <div id="add-recording-input-div">
            <Form>
              <Form.Control
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder={src === "yt" ? "Youtube link" : "Facebook link"}
                id="add-recording-input"
              />
            </Form>
          </div>
          <Button onClick={handleSubmit} id="add-recording-btn">
            Submit
          </Button>
        </Row>
      )}
    </div>
  );
};

export default AddService;
