import "./VideoEmbed.css";

const VideoEmbed = ({ link }) => {
  return (
    <iframe
      width="300"
      height="230"
      src={`${link}`}
      allowFullScreen
      title="Embed Video"
      className="embed-video-frame"
    />
  );
};

export default VideoEmbed;
