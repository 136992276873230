import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import "./Collabs.css";
// import YoutubeEmbed from "../YoutubeEmbed";
import { obtainDoc } from "../../firebase";
import { putDoc } from "../../firebase";
import { useEffect, useState } from "react";
import AddCollab from "../admin/AddCollab";
import { Fade } from "react-reveal";
const fbIcon =
  "https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Ffacebook.png?alt=media&token=3a5ace66-96fc-43a2-97e9-9cf3073b8dcb&_gl=1*74eaxs*_ga*MTk3MzIwMzA5MC4xNjgxMjkyNzU4*_ga_CW55HF8NVT*MTY4NTYwODg3MC43LjEuMTY4NTYxMDc0OS4wLjAuMA..";
const ytIcon =
  "https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fyoutube.png?alt=media&token=86c9dd0c-9a56-42bf-a3d8-3d9da3c05a0d&_gl=1*1nrvbic*_ga*MTk3MzIwMzA5MC4xNjgxMjkyNzU4*_ga_CW55HF8NVT*MTY4NTYwODg3MC43LjEuMTY4NTYxMTM0Mi4wLjAuMA..";

const Collabs = ({ userDoc }) => {
  const [collabs, setCollabs] = useState([]);
  const [addPage, setAddPage] = useState(false);
  const [deleteCollab, setDeleteCollab] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const showAdd = () => setAddPage(true);
  const hideAdd = () => setAddPage(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleHideDelete = () => setShowDelete(false);
  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => {
    setShowModal(false);
    setDeleteCollab([]);
  };

  useEffect(() => {
    obtainDoc("collabs", "collabs").then((res) =>
      setCollabs(res.data().collabs)
    );
  }, []);

  const selectedCollabToDelete = (collab) => {
    setDeleteCollab(collabs.filter((res) => res.name.includes(collab.name)));
    handleShowModal();
  };

  const handleDelete = () => {
    const collabFilter = collabs.filter(
      (res) => !res.name.includes(deleteCollab?.[0].name)
    );

    if (userDoc?.role !== "admin") {
      alert("You have no permission to perform this action");
    } else {
      putDoc("collabs", "collabs", {
        collabs: collabFilter,
      })
        .then(setCollabs(collabFilter))
        .then(handleHideModal());
    }
  };

  return (
    <div id="collabs-div">
      {!addPage ? (
        <div>
          <Modal show={showModal} onHide={handleHideModal}>
            <Modal.Header closeButton />
            <Modal.Body>
              Are you sure you want to delete this collab?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleDelete}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <div>
            <Fade duration={3000}>
              <Row>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fdeal.png?alt=media&token=1daad7c2-62b7-4136-af11-0de36b034b32&_gl=1*155pizl*_ga*MTk3MzIwMzA5MC4xNjgxMjkyNzU4*_ga_CW55HF8NVT*MTY4NTYwODg3MC43LjEuMTY4NTYxMzY4Ni4wLjAuMA.."
                  alt="deal"
                  id="collabs-icon"
                />
                <h3 id="collabs-title">Collaborations</h3>
              </Row>
            </Fade>
            {userDoc?.role === "admin" ? (
              <Row id="service-trash-btn-row">
                <Col md="auto">
                  {showDelete ? (
                    <Button
                      onClick={handleHideDelete}
                      id="service-trash-done-btn"
                    >
                      Done
                    </Button>
                  ) : (
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Ftrash-can.png?alt=media&token=573754ca-a18e-421b-bfeb-45718dff31ac&_gl=1*pym4ux*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTcwMDczNy41NS4xLjE2ODU3MDMwMTkuMC4wLjA."
                      className="service-admin-btn"
                      alt="trash"
                      onClick={handleShowDelete}
                    />
                  )}
                </Col>
                <Col md="auto">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fadd.png?alt=media&token=0009fd90-61eb-4ca2-bbd3-7f5823068e4a&_gl=1*1bmd5xl*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTY5MDEwNi41NC4xLjE2ODU2OTE5MzYuMC4wLjA."
                    className="service-admin-btn"
                    alt="add"
                    onClick={showAdd}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Fade duration={2000} bottom>
              <Row id="collabs-card-row" xs={3}>
                {collabs.map((collab, i) => (
                  <Card key={`collab-${i}`} className="collabs-card">
                    <Card.Img
                      variant="top"
                      src={collab?.imgURL}
                      className="collabs-card-img"
                    />
                    <Card.Body>
                      {showDelete ? (
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fcancel.png?alt=media&token=6fd1b18f-3ca9-4080-b27d-d9f3936485c1&_gl=1*t6yqwz*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTcwMDczNy41NS4xLjE2ODU3MDEwMzUuMC4wLjA."
                          className="service-delete-btn"
                          alt="delete"
                          onClick={() => selectedCollabToDelete(collab)}
                        />
                      ) : (
                        ""
                      )}
                      <Card.Title className="collabs-card-title">
                        {collab?.name}
                      </Card.Title>
                      <Card.Text>
                        {!collab?.fb ? (
                          ""
                        ) : (
                          <img
                            src={fbIcon}
                            alt="fbIcon"
                            className="about-link-icon"
                            onClick={() => {
                              window.open(collab?.fb, "_blank");
                            }}
                          />
                        )}{" "}
                        {""}{" "}
                        {!collab?.yt ? (
                          ""
                        ) : (
                          <img
                            src={ytIcon}
                            alt="ytIcon"
                            className="about-link-icon"
                            onClick={() => {
                              window.open(collab?.yt, "_blank");
                            }}
                          />
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </Row>
            </Fade>
          </div>
        </div>
      ) : (
        <AddCollab
          hideAdd={hideAdd}
          collabs={collabs}
          userDoc={userDoc}
          setCollabs={setCollabs}
        />
      )}
    </div>
  );
};

export default Collabs;
