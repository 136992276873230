import { Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";
import "./About.css";

const About = () => {
  return (
    <div id="about-div">
      <Fade duration={3000}>
        <Row id="about-title-row">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/images%2Fcompany-logo.gif?alt=media&token=49725779-e31d-4dce-b25a-e3c1e9b0fb34"
            alt="logo"
            id="about-company-logo"
          />
          <h3 id="about-title">DME Music Studio</h3>
          <h4 id="about-subtitle">
            DME Music Sdn. Bhd. | DME Music Enterprise
          </h4>
        </Row>
        <Row className="about-text">
          <div>
            Founded in 2017, DME Music Studio is a Klang-based professional
            recording studio that puts high-tech equipment to offer assistance
            in recording, mixing, mastering, songwriting, producing, sound
            design, and more.
          </div>
        </Row>
      </Fade>
      <Fade duration={2000} top>
        <Row id="about-profile-row">
          <Col xs="auto" id="about-founder-col">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/images%2Fdesmond.webp?alt=media&token=9cd83476-310b-4cba-a4ec-65ac82db86f6"
              alt="desmond-pic"
              id="desmond-pic"
            />
          </Col>
          <Col id="about-info-col">
            <Row id="about-info-row">
              <br />
              <h4 id="about-founder-name">Desmond Yap</h4>
              <div className="about-text">
                DME's founder, Mr. Desmond Yap Yong Juan, is a Sound Engineering
                professional graduated from ICOM Kuala Lumpur International
                Conservatory of Music. In 2019, he won the runner-up of the
                ABRSM Grand Final of High Scorers' Competition Kuala Lumpur.
                <br />
                Experienced in producing over 100 advertisement musics and sound
                productions, he is also a Music Producer, Composer, Musician,
                Performer and specialist in Mixing and Mastering.
              </div>
            </Row>
          </Col>
        </Row>
      </Fade>
    </div>
  );
};

export default About;
