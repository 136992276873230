import { Row } from "react-bootstrap";
import "./Home.css";
import { useState } from "react";
import { useEffect } from "react";
import { InstagramEmbed } from "react-social-media-embed";
import {
  createStyles,
  Overlay,
  Container,
  Title,
  Button,
  Text,
  rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";

const useStyles = createStyles((theme) => ({
  container: {
    height: rem(700),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: `calc(${theme.spacing.xl} * 6)`,
    zIndex: 1,
    position: "relative",
    backgroundColor: "rgba(255, 255, 255, 0.1)",

    [theme.fn.smallerThan("sm")]: {
      height: rem(500),
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },
  },

  title: {
    color: theme.white,
    fontSize: rem(60),
    fontWeight: 900,
    lineHeight: 1.1,
    fontFamily: "Milkalte",

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(40),
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,
    backgroundColor: "#ffd369",

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
}));

const Home = () => {
  const [facebookPage, setFacebookPage] = useState("");
  const [insLatestURL, setInsLatestURL] = useState("");
  const [insPost, setInsPost] = useState("");
  const { classes } = useStyles();
  const navigate = useNavigate();

  const token =
    "IGQVJXNFctcWt2bS1TQ1kwX1hIWVNXbnZAKRWJKaklGUHNnbm9CRFZACZAWVhYjRWT0ZA6VmFfSzRHeldFWUVpWE13YnIxYTYwYmN6UXFtYnIxR0lZAWkNkb05GVkduaWE3aEs4ZAzZAYTnJIbm1kWG1xdzRJagZDZD";

  useEffect(() => {
    setFacebookPage(
      <div
        className="fb-page"
        data-href="https://www.facebook.com/dmemusicenterprise/"
        data-tabs="timeline"
        data-width="480"
        data-height="600"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="true"
        data-show-facepile="true"
      ></div>
    );
  }, []);

  useEffect(() => {
    const fetchLatestURL = async () => {
      const userId = "6847119978650156";

      const url = `https://graph.instagram.com/${userId}/media?access_token=${token}`;

      const res = await fetch(url);
      const data = (await res.json()).data;

      const insData = data?.[0]?.id;

      const mediaURL = `https://graph.instagram.com/${insData}?access_token=${token}&fields=media_url,permalink`;

      const mediaRes = await fetch(mediaURL);
      const mediaData = await mediaRes.json();

      setInsLatestURL(mediaData?.permalink);
    };

    fetchLatestURL();
  }, []);

  useEffect(() => {
    setInsPost(
      <div>
        <InstagramEmbed
          url="https://www.instagram.com/p/CD5oK-nMeY1/"
          width={480}
        />
      </div>
    );
  }, []);

  return (
    <div id="home-div">
      <Row>
        {/* <div id="fb-page-div">{facebookPage}</div> */}
        {/* <div>{insPost}</div> */}
        <Fade duration={3000}>
          <div className="home-hero">
            <Overlay
              gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
              opacity={1}
              zIndex={0}
            />
            <Container className={classes.container}>
              <Fade duration={2000} top>
                <Title className={classes.title}>
                  Professional Music Studio
                </Title>
              </Fade>
              <Fade duration={2000} bottom>
                <Text className={classes.description} size="xl" mt="xl">
                  Unleash your creativity at our state-of-the-art music
                  production studio. Elevate your sound, collaborate with fellow
                  artists, and embark on a sonic journey like no other. Join us
                  and let your music thrive!
                </Text>

                <Button
                  variant="gradient"
                  size="xl"
                  radius="xl"
                  className={classes.control}
                  onClick={() => navigate("/contact")}
                  color="#ffd369"
                >
                  Contact Us
                </Button>
              </Fade>
            </Container>
          </div>
        </Fade>
      </Row>
    </div>
  );
};

export default Home;
