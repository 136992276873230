import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, obtainUserDoc } from "./firebase";
import { getDoc } from "firebase/firestore";
import "./App.css";
import SEOHelmet from "./components/headerFooter/SEOHelmet";
import Header from "./components/headerFooter/Header";
import UserAuth from "./components/userAuth/UserAuth";
import Home from "./components/home/Home.js";
import Recording from "./components/services/Recording";
import Arranging from "./components/services/Arranging";
import SoundDesign from "./components/services/SoundDesign";
import MixMaster from "./components/services/MixMaster";
import Collabs from "./components/collabs/Collabs";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Footer from "./components/headerFooter/Footer";
import { Notifications } from "@mantine/notifications";

const App = () => {
  const [user] = useAuthState(auth);
  const [userDoc, setUserDoc] = useState("");

  useEffect(() => {
    const fetchUserDoc = async () => {
      if (user) {
        try {
          const userRef = obtainUserDoc(user);
          const userSnap = await getDoc(userRef);
          setUserDoc(userSnap.data());
        } catch (err) {
          console.error(err.message);
          alert(err.message);
        }
      }
    };
    fetchUserDoc();
  }, [user]);

  return (
    <div className="app-div">
      <SEOHelmet />
      <Header user={user} userDoc={userDoc} />
      <div id="app-body-div">
        <Notifications />
        <div>
          <Routes>
            <Route exact path="/login" element={<UserAuth user={user} />} />
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/services/recording"
              element={<Recording userDoc={userDoc} />}
            />
            <Route
              exact
              path="/services/arranging"
              element={<Arranging userDoc={userDoc} />}
            />
            <Route
              exact
              path="/services/sound-design"
              element={<SoundDesign userDoc={userDoc} />}
            />
            <Route
              exact
              path="/services/mix-master"
              element={<MixMaster userDoc={userDoc} />}
            />
            <Route
              exact
              path="/collabs"
              element={<Collabs userDoc={userDoc} />}
            />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
