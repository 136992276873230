import { Helmet } from "react-helmet";

const SEOHelmet = () => {
  return (
    <Helmet>
      <title>DME Music Studio</title>
      <meta name="description" content="DME Music Studio" />
      <meta name="keyword" content="dme" />
      <meta name="keyword" content="music studio" />
      <meta name="keyword" content="malaysia" />
      <meta name="keyword" content="selangor" />
      <meta name="keyword" content="klang" />
      <meta name="keyword" content="music" />
      <meta name="keyword" content="music production" />
      <meta name="keyword" content="music arrangement" />
      <meta name="keyword" content="mixing" />
      <meta name="keyword" content="mastering" />
      <meta name="keyword" content="recording" />
      <meta name="keyword" content="sound design" />
      <meta name="keyword" content="advertisement" />
    </Helmet>
  );
};

export default SEOHelmet;
