import { useEffect, useState } from "react";
import { loginWithEmail, registerWithEmail } from "../../firebase";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./UserAuth.css";

const Login = ({ showRegister, navigate }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    const loginEmail = `${email}@thedmestudio.com`;

    loginWithEmail(loginEmail, password);
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      if (email.length > 0 && password.length >= 6) {
        handleLogin();
      }
    }
  };

  return (
    <div>
      <Form>
        <Form.Control
          value={email}
          placeholder="Username"
          onChange={(e) => setEmail(e.target.value)}
          className="user-auth-input"
        />
        <Form.Control
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className="user-auth-input"
          onKeyDown={handleEnterKey}
        />
        <Button onClick={handleLogin} className="user-auth-btn">
          Login
        </Button>
        {/* <div onClick={showRegister}>Register here</div> */}
      </Form>
    </div>
  );
};

const Register = ({ showLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleDisable = () => {
    if (password !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const handleRegister = () => {
    const registerEmail = `${email}@thedmestudio.com`;

    registerWithEmail(registerEmail, password);
  };

  return (
    <div>
      <Form>
        <Form.Control
          value={email}
          placeholder="E-mail"
          onChange={(e) => setEmail(e.target.value)}
          className="user-auth-input"
        />
        <Form.Control
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className="user-auth-input"
        />
        <Form.Control
          value={confirmPassword}
          placeholder="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          className="user-auth-input"
        />
        <Button onClick={handleRegister} disabled={handleDisable()}>
          Register
        </Button>
        <div onClick={showLogin}>Login here</div>
      </Form>
    </div>
  );
};

const UserAuth = ({ user }) => {
  const navigate = useNavigate();
  const [showTab, setShowTab] = useState(true);
  const showLogin = () => setShowTab(true);
  const showRegister = () => setShowTab(false);

  useEffect(() => {
    if (user) {
      return navigate("/");
    }
  }, [user, navigate]);

  return (
    <div id="user-auth-div">
      {showTab ? (
        <Login showRegister={showRegister} navigate={navigate} />
      ) : (
        <Register showLogin={showLogin} navigate={navigate} />
      )}
    </div>
  );
};

export default UserAuth;
