import { Timestamp, arrayUnion } from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import { Form, Row } from "react-bootstrap";
import { putDoc, putPhoto } from "../../firebase";
import { FileButton, Button, Group, Text } from "@mantine/core";
import "./AddService.css";
import { useNavigate } from "react-router-dom";

const AddCollab = ({ hideAdd, collabs, userDoc, setCollabs }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [fb, setFb] = useState("");
  const [yt, setYt] = useState("");
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    if (userDoc?.role !== "admin") {
      alert("You do not have the permission to view this page");
      navigate("/");
    }
  }, [userDoc?.role, navigate]);

  useEffect(() => {});

  console.log(photo);

  const newObject = {
    name: name,
    yt: yt,
    fb: fb,
    imgURL: `https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/collabs%2F${photo?.name}?alt=media&token=2d324ce6-f9cc-44d5-8433-b6697d28ff9e`,
    timestamp: Timestamp.fromDate(new Date()).seconds,
  };

  const filtered = collabs?.map((collab) => {
    return collab?.name;
  });

  const handleSubmit = async () => {
    if (filtered?.includes(name)) {
      alert("Duplicate collab found");
    } else {
      await putDoc("collabs", "collabs", { collabs: arrayUnion(newObject) });
      await putPhoto("collabs", photo?.name, photo);

      setName("");
      setYt("");
      setFb("");
      setPhoto("");
      alert("New collab has been added");
      setCollabs([...collabs, newObject]);
    }
  };

  return (
    <div id="add-recording-div">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fback-button.png?alt=media&token=61eeafb2-af04-41d2-8e73-435981a6bbac&_gl=1*mxo7pn*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTY5MDEwNi41NC4xLjE2ODU2OTI1NjYuMC4wLjA."
        id="add-recording-back-btn"
        alt="back"
        onClick={hideAdd}
      />
      <Row>
        <h3 id="add-recording-title">Add a new collab</h3>
      </Row>
      <Row>
        <div id="add-recording-input-div">
          <Form>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              id="add-recording-input"
            />

            <Form.Control
              value={yt}
              onChange={(e) => setYt(e.target.value)}
              placeholder="Youtube"
              id="add-recording-input"
            />
            <Form.Control
              value={fb}
              onChange={(e) => setFb(e.target.value)}
              placeholder="Facebook"
              id="add-recording-input"
            />
            <div>
              {" "}
              <Group position="center">
                <FileButton onChange={setPhoto} accept="image/png,image/jpeg">
                  {(props) => (
                    <Button {...props} id="collabs-upload-btn">
                      Upload image
                    </Button>
                  )}
                </FileButton>
              </Group>
              {photo && (
                <Text size="sm" align="center" mt="sm">
                  Picked photo: {photo.name}
                </Text>
              )}
            </div>
          </Form>
        </div>
        <Button onClick={handleSubmit} id="add-recording-btn">
          Submit
        </Button>
      </Row>
    </div>
  );
};

export default AddCollab;
