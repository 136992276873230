import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import { logout } from "../../firebase";
import logo from "../../images/logo/company-logo.gif";
import "./Header.css";

const Header = ({ user, userDoc }) => {
  const defineActiveNav = (page) => {
    if (window.location.pathname.includes(page)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="header-div">
      <Navbar expand="lg">
        <Container id="header-navbar">
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" className="company-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown
                title="Services"
                id="header-dropdown"
                active={defineActiveNav("services")}
              >
                <NavDropdown.Item href="/services/recording">
                  Recording
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/arranging">
                  Arranging
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/mix-master">
                  Mixing & Mastering
                </NavDropdown.Item>
                <NavDropdown.Item href="/services/sound-design">
                  Sound Design & Advertisement
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                className="header-link"
                active={defineActiveNav("collabs")}
                href="/collabs"
              >
                Collabs
              </Nav.Link>
              <Nav.Link
                className="header-link"
                href="/about"
                active={defineActiveNav("about")}
              >
                About
              </Nav.Link>
              <Nav.Link
                className="header-link"
                href="/contact"
                active={defineActiveNav("contact")}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {user ? (
        <div id="user-bar-div">
          Welcome back, {userDoc?.name}{" "}
          <Button onClick={logout} id="logout-btn" variant="danger">
            Logout
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Header;
