import { Carousel, Card, Row, Col, Modal, Button } from "react-bootstrap";
import "./Service.css";
import { useState, useEffect } from "react";
import { obtainDoc, putDoc } from "../../firebase";
import VideoEmbed from "../VideoEmbed";
import AddService from "../admin/AddService";
import { Fade } from "react-reveal";

const Recording = ({ userDoc }) => {
  const [addPage, setAddPage] = useState(false);
  const [videos, setVideos] = useState([]);
  const [deleteVideo, setDeleteVideo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [images, setImages] = useState([]);

  const showAdd = () => setAddPage(true);
  const hideAdd = () => setAddPage(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleHideDelete = () => setShowDelete(false);
  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => {
    setShowModal(false);
    setDeleteVideo([]);
  };

  useEffect(() => {
    obtainDoc("videos", "recording").then((res) => setVideos(res.data().list));
  }, []);

  useEffect(() => {
    obtainDoc("images", "services").then((res) =>
      setImages(res.data().recording)
    );
  }, []);

  let carousels = [];

  images?.forEach((link, i) => {
    let imgCarousels = (
      <Carousel.Item key={`service-carousel-${i}`}>
        <img src={link} alt="carousel" className="service-carousel-img" />
      </Carousel.Item>
    );

    carousels.push(imgCarousels);
  });

  const selectedVideoToDelete = (video) => {
    setDeleteVideo(videos.filter((res) => res.link.includes(video.link)));
    handleShowModal();
  };

  const handleDelete = () => {
    const videoFilter = videos.filter(
      (res) => !res.link.includes(deleteVideo?.[0].link)
    );

    if (userDoc?.role !== "admin") {
      alert("You have no permission to perform this action");
    } else {
      putDoc("videos", "recording", {
        list: videoFilter,
      })
        .then(setVideos(videoFilter))
        .then(handleHideModal());
    }
  };

  return (
    <div id="service-div">
      {!addPage ? (
        <div>
          <Modal show={showModal} onHide={handleHideModal}>
            <Modal.Header closeButton />
            <Modal.Body>Are you sure you want to delete this video?</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleDelete}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <Fade duration={3000}>
            <div id="service-intro-div">
              <Row>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fvoice-search.png?alt=media&token=706681f9-20a1-4e0d-aa25-f143fcb25b96&_gl=1*d6opif*_ga*MTk3MzIwMzA5MC4xNjgxMjkyNzU4*_ga_CW55HF8NVT*MTY4NTYwODg3MC43LjEuMTY4NTYxMzgwMC4wLjAuMA.."
                  alt="sound-wave"
                  id="service-icon"
                />
                <h3 id="service-title">Recording</h3>
                <h3 id="service-subtitle">Recording Studio | Sound Expert</h3>
              </Row>
              <Row id="service-intro-row">
                We at DME are passionate about audio and music, and we utilize
                top-notch equiment for all your song or album's tracking and
                mixing needs. We're a single live room facility with standard
                recording equipments to accommodate recording needs and a cosy
                atmosphere to make you feel at home. Our in house
                engineers/producer, Desmond Yap, has the experience to manage
                all aspects of pre and post production services. Our commitment
                is towards YOU, our esteemed client and your creative and
                artistic endeavors.
              </Row>
              <Row id="service-carousel-row">
                <Carousel id="service-carousel" variant="dark" pause="hover">
                  {carousels}
                </Carousel>
              </Row>
            </div>
          </Fade>
          {userDoc?.role === "admin" ? (
            <Row id="service-trash-btn-row">
              <Col md="auto">
                {showDelete ? (
                  <Button
                    onClick={handleHideDelete}
                    id="service-trash-done-btn"
                  >
                    Done
                  </Button>
                ) : (
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Ftrash-can.png?alt=media&token=573754ca-a18e-421b-bfeb-45718dff31ac&_gl=1*pym4ux*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTcwMDczNy41NS4xLjE2ODU3MDMwMTkuMC4wLjA."
                    className="service-admin-btn"
                    alt="trash"
                    onClick={handleShowDelete}
                  />
                )}
              </Col>
              <Col md="auto">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fadd.png?alt=media&token=0009fd90-61eb-4ca2-bbd3-7f5823068e4a&_gl=1*1bmd5xl*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTY5MDEwNi41NC4xLjE2ODU2OTE5MzYuMC4wLjA."
                  className="service-admin-btn"
                  alt="add"
                  onClick={showAdd}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Fade duration={2000} bottom>
            <Row xs={1} md={2} id="service-video-card-row">
              {videos
                ?.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
                .map((video, i) => (
                  <Card
                    key={`service-video-${i}`}
                    className="service-videos-card"
                  >
                    {showDelete ? (
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fcancel.png?alt=media&token=6fd1b18f-3ca9-4080-b27d-d9f3936485c1&_gl=1*t6yqwz*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTcwMDczNy41NS4xLjE2ODU3MDEwMzUuMC4wLjA."
                        className="service-delete-btn"
                        alt="delete"
                        onClick={() => selectedVideoToDelete(video)}
                      />
                    ) : (
                      ""
                    )}
                    <Card.Body className="service-video-card-body">
                      <VideoEmbed link={video?.link} src={video?.src} />
                    </Card.Body>
                  </Card>
                ))}
            </Row>
          </Fade>
        </div>
      ) : (
        <Row>
          <AddService
            hideAdd={hideAdd}
            videos={videos}
            userDoc={userDoc}
            setVideos={setVideos}
          />
        </Row>
      )}
    </div>
  );
};

export default Recording;
