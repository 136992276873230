import { Col, Row } from "react-bootstrap";
import "./Footer.css";
import companyLogo from "../../images/logo/company-logo.gif";

const Footer = () => {
  return (
    <div className="footer-div">
      <Row className="footer-info-row">
        <Col id="footer-info-div" md={5}>
          <Row>
            <Col md="auto" id="footer-add">
              18, Jalan Dato Yusuf Shahbuddin 43,
              <br />
              Taman Sentosa, Klang, <br />
              Selangor, Malaysia.
            </Col>
            <Col id="footer-contact">
              +6016-6609656 <br />
              dmemusic88@gmail.com
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <div id="footer-copyright">
            <img src={companyLogo} alt="logo" id="footer-company-logo" />
            <br />
            Copyright © DME Sdn. Bhd. {new Date().getFullYear()}
          </div>
        </Col>
        <Col id="footer-icon-div" md={5}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Ffacebook.png?alt=media&token=3a5ace66-96fc-43a2-97e9-9cf3073b8dcb&_gl=1*ylehhm*_ga*MTk3MzIwMzA5MC4xNjgxMjkyNzU4*_ga_CW55HF8NVT*MTY4NTYwODg3MC43LjEuMTY4NTYxMTQxMC4wLjAuMA.."
            alt="fb"
            className="footer-icon"
            onClick={() =>
              window.open(
                "https://www.facebook.com/dmemusicenterprise/",
                "_blank"
              )
            }
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Finstagram.png?alt=media&token=f8d6cb48-27a1-4eca-bcdf-b624493d4957&_gl=1*7vk5wn*_ga*MTk3MzIwMzA5MC4xNjgxMjkyNzU4*_ga_CW55HF8NVT*MTY4NTYwODg3MC43LjEuMTY4NTYxMTQyNy4wLjAuMA.."
            alt="ins"
            className="footer-icon"
            onClick={() =>
              window.open("https://www.instagram.com/dmemusic88/", "_blank")
            }
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fyoutube.png?alt=media&token=86c9dd0c-9a56-42bf-a3d8-3d9da3c05a0d&_gl=1*1nrvbic*_ga*MTk3MzIwMzA5MC4xNjgxMjkyNzU4*_ga_CW55HF8NVT*MTY4NTYwODg3MC43LjEuMTY4NTYxMTM0Mi4wLjAuMA.."
            alt="yt"
            className="footer-icon"
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCt05Rnknd9o9Fk2wtYtn7vQ/",
                "_blank"
              )
            }
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fsoundcloud.png?alt=media&token=0677236d-2b33-4027-b1bb-f0f92ffb9cfc&_gl=1*tv9nrq*_ga*MTk3MzIwMzA5MC4xNjgxMjkyNzU4*_ga_CW55HF8NVT*MTY4NTYwODg3MC43LjEuMTY4NTYxMTQ1My4wLjAuMA.."
            alt="sc"
            className="footer-icon"
          />
        </Col>
      </Row>
      <Row></Row>
    </div>
  );
};

export default Footer;
