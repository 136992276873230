import { Button, Modal, Row, Card, Col } from "react-bootstrap";
import "./Service.css";
import { useEffect, useState } from "react";
import AddService from "../admin/AddService";
import { obtainDoc, putDoc } from "../../firebase";
import VideoEmbed from "../VideoEmbed";
import { Fade } from "react-reveal";

const MixMaster = ({ userDoc }) => {
  const [addPage, setAddPage] = useState(false);
  const [videos, setVideos] = useState([]);
  const [deleteVideo, setDeleteVideo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    obtainDoc("videos", "mix").then((res) => setVideos(res.data().list));
  }, []);

  const showAdd = () => setAddPage(true);
  const hideAdd = () => setAddPage(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleHideDelete = () => setShowDelete(false);
  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => {
    setShowModal(false);
    setDeleteVideo([]);
  };

  const selectedVideoToDelete = (video) => {
    setDeleteVideo(videos.filter((res) => res.link.includes(video.link)));
    handleShowModal();
  };

  const handleDelete = () => {
    const videoFilter = videos.filter(
      (res) => !res.link.includes(deleteVideo?.[0].link)
    );

    if (userDoc?.role !== "admin") {
      alert("You have no permission to perform this action");
    } else {
      putDoc("videos", "mix", {
        list: videoFilter,
      })
        .then(setVideos(videoFilter))
        .then(handleHideModal());
    }
  };

  return (
    <div id="service-div">
      {!addPage ? (
        <div>
          <Modal show={showModal} onHide={handleHideModal}>
            <Modal.Header closeButton />
            <Modal.Body>Are you sure you want to delete this video?</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleDelete}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <Fade duration={3000}>
            <div id="service-intro-div">
              <Row>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Flayer.png?alt=media&token=9f406cf0-86f5-46ee-8d25-9bf742e92786"
                  alt="sound-wave"
                  id="service-icon"
                />
                <h3 id="service-title">Mixing and Mastering</h3>
                <h3 id="service-subtitle">Adaptation | Reharmonization</h3>
              </Row>
              <Row id="service-intro-row">
                DME offers professional music mixing and mastering services.
                With our expertise in audio engineering, we skillfully blend and
                balance tracks, enhancing clarity, depth, and sonic impact. We
                are equipped to bring out the full potential of your music with
                precision and finesse in the final mastering stage.
              </Row>
            </div>
          </Fade>
          {userDoc?.role === "admin" ? (
            <Row id="service-trash-btn-row">
              <Col md="auto">
                {showDelete ? (
                  <Button
                    onClick={handleHideDelete}
                    id="service-trash-done-btn"
                  >
                    Done
                  </Button>
                ) : (
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Ftrash-can.png?alt=media&token=573754ca-a18e-421b-bfeb-45718dff31ac&_gl=1*pym4ux*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTcwMDczNy41NS4xLjE2ODU3MDMwMTkuMC4wLjA."
                    className="service-admin-btn"
                    alt="trash"
                    onClick={handleShowDelete}
                  />
                )}
              </Col>
              <Col md="auto">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fadd.png?alt=media&token=0009fd90-61eb-4ca2-bbd3-7f5823068e4a&_gl=1*1bmd5xl*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTY5MDEwNi41NC4xLjE2ODU2OTE5MzYuMC4wLjA."
                  className="service-admin-btn"
                  alt="add"
                  onClick={showAdd}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Fade duration={2000} bottom>
            <Row xs={1} md={2} id="service-video-card-row">
              {videos
                ?.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
                ?.map((video, i) => (
                  <Card
                    key={`service-video-${i}`}
                    className="service-videos-card"
                  >
                    {showDelete ? (
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fcancel.png?alt=media&token=6fd1b18f-3ca9-4080-b27d-d9f3936485c1&_gl=1*t6yqwz*_ga*MTQ4MTk3NjczMy4xNjc2MDQ0MDg4*_ga_CW55HF8NVT*MTY4NTcwMDczNy41NS4xLjE2ODU3MDEwMzUuMC4wLjA."
                        className="service-delete-btn"
                        alt="delete"
                        onClick={() => selectedVideoToDelete(video)}
                      />
                    ) : (
                      ""
                    )}
                    <Card.Body className="service-video-card-body">
                      <VideoEmbed link={video?.link} src={video?.src} />
                    </Card.Body>
                  </Card>
                ))}
            </Row>
          </Fade>
        </div>
      ) : (
        <Row>
          <AddService
            hideAdd={hideAdd}
            videos={videos}
            userDoc={userDoc}
            setVideos={setVideos}
          />
        </Row>
      )}
    </div>
  );
};

export default MixMaster;
