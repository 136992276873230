import { Fade } from "react-reveal";
import { Container, Input, Textarea, Text, Button, Flex } from "@mantine/core";
import { notifications, Notifications } from "@mantine/notifications";
import "./Contact.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  // const form = useForm({
  //   initialValues: {
  //     email: "",
  //     name: "",
  //     subject: "",
  //     message: "",
  //   },
  // });

  // const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("test", "test", "#form", "trKaTLjw6oAXNyrEE").then(
      (result) => {
        console.log(result.text);
        notifications.show({
          title: "Your e-mail has been sent",
          autoClose: 5000,
          withCloseButton: true,
        });
      },
      (error) => {
        console.log(error.text);
        notifications.show({
          title: "An error has occured",
          message: "You e-mail was not sent",
          autoClose: 5000,
          withCloseButton: true,
          color: "red",
        });
      }
    );

    e.target.reset();
  };

  return (
    <div className="contact-div">
      <Container className="contact-container">
        <Fade duration={3000}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fphone-call.png?alt=media&token=d5a03d7c-23c2-451f-b7f4-c624dd097504"
            alt="phone"
            className="contact-icon"
          />
          <h2 className="contact-title">
            Looking to get in touch with us? Send us an e-mail using the form
            below
          </h2>
          <form onSubmit={sendEmail} id="form">
            <Input
              placeholder="E-mail Address"
              className="contact-input"
              required
              type="email"
              // {...form.getInputProps("email")}
              name="user_email"
            />
            <Input
              placeholder="Full Name"
              className="contact-input"
              required
              type="text"
              // {...form.getInputProps("name")}
              name="user_name"
            />
            <Input
              placeholder="Subject"
              className="contact-input"
              required
              type="text"
              // {...form.getInputProps("subject")}
              name="subject"
            />
            <Textarea
              placeholder="Why are you writing to us?"
              className="contact-textarea"
              required
              minRows={4}
              autosize
              type="text"
              // {...form.getInputProps("message")}
              name="message"
            />
            <Button id="contact-send-button" type="submit">
              Send
            </Button>
          </form>
        </Fade>
      </Container>
      <Container className="contact-container">
        <Fade duration={2000} top>
          <h2 className="contact-title">Or, call/WhatsApp us at</h2>
          <Flex
            justify="center"
            align="center"
            direction="row"
            id="service-number-flex"
          >
            <Text className="contact-text">+(6)016-660 9656</Text>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/dmestudio-66f59.appspot.com/o/Icons%2Fwhatsapp.png?alt=media&token=25a938bb-38a5-442a-bf74-1c1cdd6cc30c"
              alt="whatsapp"
              onClick={() => {
                window.open("https://wa.me/+60106609656", "_blank");
              }}
              id="whatsapp-btn"
            />
          </Flex>
        </Fade>
      </Container>
      <Container className="contact-container">
        <Fade duration={3000}>
          <h2 className="contact-title">You can also find us at</h2>
          <iframe
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDgmgqB1hDqw8Tk8f_vSJerRVrXTLMtXxM
    &q=DME Music Enterprise"
            className="contact-map"
          />
        </Fade>
      </Container>
    </div>
  );
};

export default Contact;
