import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCyR6lEBWMw7Vm7bfFW8eYaioZkuekCUU8",
  authDomain: "dmestudio-66f59.firebaseapp.com",
  projectId: "dmestudio-66f59",
  storageBucket: "dmestudio-66f59.appspot.com",
  messagingSenderId: "198313315437",
  appId: "1:198313315437:web:76d5095a2a40e1d492c364",
  measurementId: "G-0XPX4RBGJP",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const obtainUserDoc = (user) => doc(db, "users", user?.uid);

const obtainDoc = (collection, document) => {
  const docRef = doc(db, collection, document);
  const docSnap = getDoc(docRef);

  return docSnap;
};

const putDoc = (collection, document, newObject) => {
  const docRef = doc(db, collection, document);
  const update = updateDoc(docRef, newObject);

  return update;
};

const putPhoto = (collection, name, file) => {
  const photoRef = ref(storage, `${collection}/${name}`);
  uploadBytes(photoRef, file).then((snapshot) => {
    console.log("Upload Successful");
  });
};

const loginWithEmail = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmail = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const name = email.substring(0, email.indexOf("@"));
    await setDoc(doc(db, "users", user.uid), {
      name: name,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = async () => {
  await signOut(auth);
  alert("You have logged out");
  window.location.reload();
};

export {
  auth,
  loginWithEmail,
  registerWithEmail,
  logout,
  obtainUserDoc,
  obtainDoc,
  putDoc,
  putPhoto,
};
